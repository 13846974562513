import React from 'react'
import { useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useLocation, useRouteMatch } from 'react-router-dom'
import Button from 'components/Button'
import Icon from 'components/Icon'
import propTypes from 'prop-types'
import { selectCurrentUser, selectCurrentZone } from 'store/global'
import messages from './messages'
import styles from './Header.scss'
import { downloadCSV } from 'utils/downloadCSV'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { exportSingleEntityCSV } from 'services/verticalsApi'
import { logoutRequest } from './utils'

function Header({ intl, currentZoneIndex }) {
  const location = useLocation()
  const { trackEvent } = useMatomo()

  const match = useRouteMatch('/vertical/:vertical/:entityId')

  const currentZone = useSelector(selectCurrentZone)

  const currentUser = useSelector(selectCurrentUser)
  const { email = '', fullName = '' } = currentUser || {}

  const { name = '' } = currentZone || {}
  const currentZoneName = name

  const onCSVExport = async () => {
    const entityId = match.params.entityId
    const csvType = `single-entity-${match.params.vertical}`
    const entityCSV = await exportSingleEntityCSV(entityId, currentZoneIndex, csvType)

    downloadCSV(entityCSV, 'single-entity-' + entityId)
    trackEvent({ category: 'Export', action: 'User requested for single entity CSV' })
  }

  return (
    <header className={styles.header}>
      <Button to='/dashboard' color='link' className={styles.logoLink} title={intl.formatMessage(messages.logo)}>
        <Icon name='logo' size='xl' className={styles.logo} />
      </Button>
      <div className={styles.linksWrapper}>
        <ul className={styles.linksList}>
          <li className={styles.linkItem}>
            <Button color='link' to={email ? '/profile' : undefined} title={email} className={styles.profile}>
              <span className={styles.username}>{fullName}</span>
            </Button>
            <Button
              color='link'
              onClick={() => {
                logoutRequest().then(res => {
                  window.location.href = `${res.data.logoutUrl}?id_token_hint=${res.data.idToken}&post_logout_redirect_uri=${window.location.origin}`
                })
              }}
            >
              <Icon name='logout' size='regular' className={styles.logout} />
            </Button>
          </li>
          <li className={styles.linkItem}>
            <Button color='link'>
              <Icon name='bell' size='regular' className={styles.bellIcon} />
            </Button>
          </li>
          <li className={styles.linkItem}>
            {currentZoneName && (
              <div className={styles.currentZone}>
                <span className={styles.zoneName}>{currentZoneName}</span>
              </div>
            )}
          </li>
          {location.pathname.includes('/vertical') && (
            <li>
              <Button color='light' outline className={styles.export} disabled={false} onClick={onCSVExport}>
                <Icon name='exportFile' size='regular' />
                EXPORT
              </Button>
            </li>
          )}
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  intl: propTypes.object.isRequired,
}

export default injectIntl(Header)
